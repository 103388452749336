// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

body {
    background-color: #121212;
    color: #fff;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollable {
    overflow-y: hidden;
}

.scrollable:hover {
    overflow-y: scroll;
}

.banner-container {
    height: 100vh;
}

.image-container {
    z-index: -1;
    height: auto;
    position: relative;
}

.image-container img {
    width: 70rem;
    float: right;
    object-fit: contain;
}

.image-container:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 70rem;
    top: 0;
    right: 0;
    background-image: linear-gradient(0deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 0) 30%),
        linear-gradient(90deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 0) 30%),
        linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 0) 30%);
}

.slick-current .item {
    background-color: #414f68 !important;
    transform: scale(1.2, 1.2);
    transition: ease 350ms;
}

#checkbox-menu {
    position: absolute;
    opacity: 0;
}

#label-menu {
    cursor: pointer;
    position: relative;
    display: block;
    height: 22px;
    width: 30px;
}

#label-menu span {
    position: absolute;
    display: block;
    height: 5px;
    width: 100%;
    border-radius: 30px;
    background: #8fb7dd;
    transition: 0.25s ease-in-out;
}

#label-menu span:nth-child(1) {
    top: 0;
}

#label-menu span:nth-child(2) {
    top: 8px;
}

#label-menu span:nth-child(3) {
    top: 16px;
}

#checkbox-menu:checked + #label-menu span:nth-child(1) {
    transform: rotate(-45deg);
    top: 8px;
}

#checkbox-menu:checked + #label-menu span:nth-child(2) {
    opacity: 0;
}

#checkbox-menu:checked + #label-menu span:nth-child(3) {
    transform: rotate(45deg);
    top: 8px;
}

.aside {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
}

.video_wrapper {
    position: relative;
    padding-bottom: 56.25%;
}

.video_wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25rem;
}

.video_wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.list_container {
    display: flex;
    // margin-top: 50px;
}

.item {
    position: relative;
    // display: block;
    flex: 1 1 0px;
    transition: transform 250ms;
}

.list_container:focus-within .item,
.list_container:hover .item {
    transform: translateX(-12%);
}

.item:focus ~ .item,
.item:hover ~ .item {
    // transform: translateX(12%);
}

.list_container .item:focus,
.list_container .item:hover {
    transform: scale(1.2);
    z-index: 1;
}

#epg_image {
    opacity: 0;
}

#epg_image:hover {
    opacity: 1;
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 50px;
    height: 50px;

    & .path {
        stroke: rgb(255, 159, 80);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

.list-circle {
    list-style-type: circle;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

@media (max-width: 480px) {
    .image-container:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 70rem;
        top: 0;
        right: 0;
        background-image: linear-gradient(0deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 0) 30%),
            linear-gradient(90deg, rgba(18, 18, 18, 1) 30%, rgba(0, 0, 0, 0) 100%),
            linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 0) 30%);
    }
}
