* {
    margin: 0;
    padding: 0;
}

.slider-frame {
    // background-color: #121212;
    // background-color: rgb(45, 55, 72);
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    .btn {
        width: 40px;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        z-index: 10;

        &.prev {
            left: 0px;
        }

        &.next {
            right: 0px;
        }
    }

    .slider-container {
        transition: margin-left .2s ease, left .5s ease;
        position: absolute;
        left: 0;

        .slide {
            color: white;
            float: left;
            position: relative;
            top: 0;
            margin-right: 5px;
            transition: width .2s ease, height .2s ease, top .2s ease;
        }
    }
}